import React, { useState } from "react"
import { connect, useSelector } from "react-redux"
import { ContactUsQuery, ContactUsFormQuery } from "../../prismic/staticQueries"
import Layout from "../../components/layout"
import {
  SubscriptionPanel,
  BreadCrumbBanner,
  AddFormFields,
} from "../../components/shared"
import { Form } from "react-bootstrap"
import { sendEmail } from "../../actions"
import { SUCCESS_MESSAGES } from "../../constants"
const ContactUs = () => {
  const language = useSelector(state => state.language)
  const contactUsData = ContactUsQuery(language)
  const contactUsFormData = ContactUsFormQuery(language)

  const [formData, updateFormData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState(null)

  const handleChange = e => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    })
  }

  const handleSubmit = async e => {
    setIsLoading(true)
    setMessage(null)
    e.preventDefault()
    try {
      await sendEmail("contact-us", formData)
      setMessage(SUCCESS_MESSAGES.EMAIL_SENT[language])
      updateFormData({})
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }
  return (
    <Layout>
      <BreadCrumbBanner
        banner_image={contactUsData.banner_image}
        heading={contactUsData.heading_title}
      />
      <div style={{ clear: "both" }}></div>
      <section className="contact-split align-items-center mb-5">
        <div className="row">
          <div className="container-fluid">
            <div className="col-lg-6 col-sm-6 col-xs-12 no-padding panel">
              <div className="g-maps">
                <iframe
                  title="iframe"
                  className="map"
                  src={contactUsData.map_url[0].text}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen=""
                ></iframe>
                <div className="map-top col-lg-10 col-md-12">
                  <ul className="icon-list--three">
                    {contactUsData.address.map((address, addressInd) => (
                      <li className="icon-list2 d-flex" key={'sdfsd'+addressInd}>
                        <div className="icon">
                          <i className={address.address_icon[0].text}></i>
                        </div>
                        <div className="contents">
                          <h6>{address.address_title[0].text}</h6>
                          {address.address_descriptions.map((line, lineInd) => (
                            <div key={'sdfsdfsdf'+lineInd}>
                              <span className="sub-text">{line.text}</span>
                              <br />
                            </div>
                          ))}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-xs-12  panel">
              <div className="row">
                <div className="container">
                  <div className="col-lg-8 col-md-10 col-sm-12 contact-form-page form-wrapper">
                    <div className="m-bottom-10 mt-5">
                      <div className="divider divider-simple">
                        <h3>{contactUsData.contact_us_form_title[0].text}</h3>
                      </div>
                    </div>

                    <p className="m-bottom-30">
                      {contactUsData.contact_us_form_description[0].text}
                    </p>
                    {message ? (
                      <div className="alert alert-success">{message}</div>
                    ) : (
                      ""
                    )}

                    <Form id="contact-us-form" onSubmit={handleSubmit}>
                      <AddFormFields
                        data={contactUsFormData.body[0].items}
                        label="none"
                        onChange={handleChange}
                      />
                      <Form.Group>
                        <button
                          className="btn"
                          disabled={isLoading}
                          type="submit"
                          style={{
                            backgroundColor: "#1175bb",
                            color: "#fff",
                          }}
                        >
                          {contactUsFormData.submit_button_text[0].text}
                        </button>
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ clear: "both" }}></div>
          </div>
        </div>
      </section>
      <div style={{ clear: "both" }}></div>

      <SubscriptionPanel />
    </Layout>
  )
}

const mapStateToProps = state => ({ language: state.language })

export default connect(mapStateToProps)(ContactUs)
